<script setup lang="ts">
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';
import { handleClick } from '@/helpers/clickHandler';

type Props = {
  content: {
    data: {
      media: {
        url: string | null;
        alt: string | null;
        title: string;
        thumbnails: {
          height: number;
          id: string | number;
          url: string | null;
          width: number;
        }[];
      };
      mobileMedia: {
        url: string | null;
        alt: string | null;
        title: string;
        thumbnails: {
          height: number;
          id: string | number;
          url: string;
          width: number;
        }[];
      };
      content: string;
    };
    translated: {
      config: {
        textAlignment: {
          value: string;
        };
        brandPatternActive: {
          value: boolean;
        };
        brandPatternColor: {
          value: string;
        };
      };
    };
  };
};

const props = defineProps<Props>();

const bannerData = computed(() => {
  const { media, mobileMedia, content } = props.content.data;

  const config = props.content.translated.config;

  return {
    textAlignment: config.textAlignment?.value,
    desktopBgImage: media ? media?.url : '',
    mobileBgImage: mobileMedia ? mobileMedia?.url : '',
    textOnBanner: content,
    altText: media ? media?.alt : '',
    titleText: media ? media?.title : '',
    mobileAltText: mobileMedia ? mobileMedia?.alt : '',
    mobileTitleText: mobileMedia ? mobileMedia?.title : '',
    isPattern: config.brandPatternActive?.value,
    bcgPatternColor: config.brandPatternColor?.value,
    srcsetMobile: mobileMedia ? getSrcSetForMedia(mobileMedia as any) : '',
    srcsetDesktop: media ? getSrcSetForMedia(media as any) : '',
    //for pattern:
    srcset:
      (config.brandPatternActive?.value && !mobileMedia) || !media
        ? getSrcSetForMedia(media as any)
        : media.url || '',
  };
});
</script>

<template>
  <div>
    <Banner
      :text-alignment="bannerData.textAlignment"
      :desktop-bg-image="bannerData.desktopBgImage"
      :mobile-bg-image="bannerData.mobileBgImage"
      :text-on-banner="bannerData.textOnBanner"
      :alt-text="bannerData.altText"
      :mobile-alt-text="bannerData.mobileAltText"
      :title-text="bannerData.titleText"
      :mobile-title-text="bannerData.mobileTitleText"
      :is-pattern="bannerData.isPattern"
      :bcg-pattern-color="bannerData.bcgPatternColor"
      :srcset-mobile="bannerData.srcsetMobile"
      :srcset-desktop="bannerData.srcsetDesktop"
      :srcset="bannerData.srcset"
      @banner-link-clicked="handleClick"
    />
  </div>
</template>
